import { generateCsvByKeys } from 'src/utils/generateFiles';

import { Descriptions, DescriptionsItem, Table } from './DataDisplays';
import { Modal, Progress, Spin } from './Feedbacks';
import { Button } from './Generals';
import { ClearIcon, ErrorIcon, SuccessIcon, WarningIcon } from './Icons';
import { Column, Row } from './Layouts';

type ModalProps = {
  isVisible: boolean;
  hideModal: () => void;
  bulkOrdersJob: Map<string, string>;
  clearBulkOrdersJob: () => void;
};

type StatusProps = {
  status: 'pending' | 'success' | 'error' | 'timeout';
};

const Status = ({ status }: StatusProps) => {
  switch (status) {
    case 'pending':
      return <Spin />;
    case 'success':
      return SuccessIcon;
    case 'error':
      return ErrorIcon;
    case 'timeout':
      return WarningIcon;
    default:
      return <>{status}</>;
  }
};

const columns = [
  {
    title: 'Site Order Id',
    dataIndex: 'siteOrderId',
    width: 50,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 50,
    render: (val: any) => <Status status={val} />,
  },
];

const BulkOrdersJobStatusModal = ({
  isVisible,
  hideModal,
  bulkOrdersJob,
  clearBulkOrdersJob,
}: ModalProps) => {
  let successful = 0;
  let failed = 0;
  let timeout = 0;
  const total = bulkOrdersJob.size;
  const dataSource = Array.from(bulkOrdersJob, ([key, value]) => {
    switch (value) {
      case 'success':
        ++successful;
        break;
      case 'error':
        ++failed;
        break;
      case 'timeout':
        ++timeout;
        break;
    }

    return {
      siteOrderId: key,
      status: value,
    };
  });
  const isDownloadBtnVisible = total === successful + failed + timeout;

  const generateCSVFile = () => {
    generateCsvByKeys(
      ['Site Order Id', 'Status'],
      ['siteOrderId', 'status'],
      dataSource,
      'bulk'
    );
  };

  const progressPercent = ((successful + failed + timeout) / total) * 100;
  const modalTitle = (
    <>
      <h3>Bulk Execution</h3>
      <Progress
        showInfo={true}
        size="small"
        percent={progressPercent}
        success={{ percent: successful }}
      />
    </>
  );
  const footer = (
    <Row>
      <Column size={12}>
        <Descriptions
          layout="horizontal"
          size="small"
          column={1}
          bordered={true}
        >
          <DescriptionsItem label="Total">{total}</DescriptionsItem>
          <DescriptionsItem label="Successful">{successful}</DescriptionsItem>
          <DescriptionsItem label="Failed">{failed}</DescriptionsItem>
          <DescriptionsItem label="Timeout">{timeout}</DescriptionsItem>
        </Descriptions>
      </Column>
      <Column size={12}>
        <Descriptions
          layout="horizontal"
          size="small"
          column={1}
          bordered={true}
        >
          <DescriptionsItem label="Clear Job">
            <Button type="text" icon={ClearIcon} onClick={clearBulkOrdersJob} />
          </DescriptionsItem>
          {isDownloadBtnVisible && (
            <DescriptionsItem label="Export as Sheet">
              <Button type="text" icon={ClearIcon} onClick={generateCSVFile} />
            </DescriptionsItem>
          )}
        </Descriptions>
      </Column>
    </Row>
  );

  return (
    <Modal
      title={modalTitle}
      visible={isVisible}
      onCancel={hideModal}
      footer={footer}
    >
      <Table
        rowKey="siteOrderId"
        columns={columns}
        dataSource={dataSource}
        scroll={{ y: 250 }}
      />
    </Modal>
  );
};

export default BulkOrdersJobStatusModal;

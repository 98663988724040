import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Form, FormItem } from 'src/components/Form';
import { Button } from 'src/components/Generals';
import { Input } from 'src/components/Inputs';
import { buildFormRules } from 'src/utils/formRulesBuilder';

import { useSendEmail, useValidateEmail } from '../api/resetPassword';

const ResetPassword = () => {
  const [userEmail, setUserEmail] = useState('');

  const navigate = useNavigate();
  const { mutate: sendEmail, isLoading: isSending } = useSendEmail(
    userEmail,
    () => {
      navigate('/login', { replace: true });
    }
  );
  const { mutate: validateEmail, isLoading: isValidating } = useValidateEmail(
    userEmail,
    sendEmail
  );

  const onFinish = () => {
    validateEmail();
  };

  const onValuesChange = ({ email }: any) => {
    setUserEmail(email);
  };

  return (
    <Form onFinish={onFinish} onValuesChange={onValuesChange}>
      <FormItem
        label="Email"
        name="email"
        rules={buildFormRules({ required: true, email: true, max: 50 })}
      >
        <Input />
      </FormItem>
      <FormItem>
        <Button
          htmlType="submit"
          type="primary"
          block={true}
          loading={isValidating || isSending}
        >
          Submit
        </Button>
      </FormItem>
      <div className="text-center">
        <Link to="/login">Cancel</Link>
      </div>
    </Form>
  );
};

export default ResetPassword;

import { useEffect, useState } from 'react';

import storage from 'src/utils/storage';

const useAllowedBrands = () => {
  const [brands, setBrands] = useState([]);
  const [siteUrls, setSiteUrls] = useState(new Map<number, Array<string>>());
  const [allowedFirstBrand, setAllowedFirstBrand] = useState({
    id: 0,
    name: '',
  });

  useEffect(() => {
    const allowedBrands = storage.getAllowedBrands();
    const _brands = [];
    const _siteUrls = new Map<number, Array<string>>();
    allowedBrands.forEach(({ brand_id, brand_name, site_urls }) => {
      _brands.push({ id: brand_id, name: brand_name });
      _siteUrls.set(brand_id, site_urls);
    });
    setBrands(_brands);
    setSiteUrls(_siteUrls);
    if (_brands.length) setAllowedFirstBrand(_brands[0]);
  }, []);

  return { brands, siteUrls, allowedFirstBrand };
};

export default useAllowedBrands;

import { queryClient } from 'src/lib/react-query';

const idsKey = 'selectedSiteOrderIds';

export const setSelectedOrderIds = (siteOrderIds: Array<string>) => {
  queryClient.setQueryData(idsKey, siteOrderIds);
};

export const getSelectedOrderIds = () => {
  const queryData = queryClient.getQueryData(idsKey);
  return Array.isArray(queryData) ? queryData : [];
};

export const removeSelectedOrderIds = () => {
  queryClient.removeQueries(idsKey);
};

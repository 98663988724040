import { BrandType, RouteType } from '../types';

const storage = {
  setUserId: (id: number) => {
    window.localStorage.setItem('userId', id.toString());
  },
  setUserFullName: (name: string) => {
    window.localStorage.setItem('username', name);
  },
  setUserEmail: (email: string) => {
    window.localStorage.setItem('email', email);
  },
  setAllowedBrands: (brands: Array<BrandType>) => {
    window.localStorage.setItem('brands', JSON.stringify(brands));
  },
  setAllowedRoutes: (routes: Array<RouteType>) => {
    window.localStorage.setItem('routes', JSON.stringify(routes));
  },
  setPermissions: (permissions: Array<string>) => {
    window.localStorage.setItem('permissions', JSON.stringify(permissions));
  },
  setToken: (token: string) => {
    window.localStorage.setItem('token', token);
  },
  setIsOEAdmin: (check: boolean) => {
    window.localStorage.setItem('isOEAdmin', String(check));
  },
  getUserId: (): number => {
    return JSON.parse(window.localStorage.getItem('userId') as string);
  },
  getUserFullName: (): string => {
    return window.localStorage.getItem('username') as string;
  },
  getUserEmail: (): string => {
    return window.localStorage.getItem('email') as string;
  },
  getAllowedBrands: (): Array<BrandType> => {
    return JSON.parse(window.localStorage.getItem('brands') as string);
  },
  getAllowedFirstBrand: (): BrandType => {
    const brands = storage.getAllowedBrands();
    return brands && brands[0];
  },
  getAllowedBrandById: (id: number): BrandType => {
    const brands = storage.getAllowedBrands();
    return brands.find((it) => it.brand_id == id);
  },
  getUserRoutes: (): Array<RouteType> => {
    return JSON.parse(window.localStorage.getItem('routes') as string);
  },
  getUserRouteChildren: (query: string): RouteType => {
    const routes = storage.getUserRoutes();
    return routes.find((it) => it.route == query || it.title == query);
  },
  getPermissions: () => {
    return JSON.parse(window.localStorage.getItem('permissions') as string);
  },
  getToken: () => {
    return window.localStorage.getItem('token') as string;
  },
  getIsOEAdmin: () => {
    return JSON.parse(window.localStorage.getItem('isOEAdmin') as string);
  },
  clearStorage: () => {
    window.localStorage.clear();
  },
  // check permission for old react app
  checkUserPermission: (permission: string) => {
    const permissions = storage.getPermissions();
    return permissions.includes(permission);
  },
};

export default storage;

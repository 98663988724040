import { Link, useNavigate } from 'react-router-dom';

import { Form, FormItem } from 'src/components/Form';
import { Button } from 'src/components/Generals';
import { Input, PasswordInput } from 'src/components/Inputs';
import { secretToken } from 'src/constants';
import { buildFormRules } from 'src/utils/formRulesBuilder';
import storage from 'src/utils/storage';

import { useAuthentication, useToken } from '../api/login';

const sortBrands = (brands: Array<any>) => {
  for (const i in brands) {
    let name = brands[i]['brand_name'];
    name = name.replace(/\s/g, '').toLowerCase();
    brands[i]['name'] = name;
  }

  brands = brands.sort((a, b) =>
    a['name'] > b['name'] ? 1 : b['name'] > a['name'] ? -1 : 0
  );

  return brands;
};

const parserUserMenus = (data: Array<any>) => {
  if (!data || !data.length) {
    return [];
  }

  const parsedData: Array<{
    title: string;
    route: string;
    children: Array<any>;
  }> = [];
  data.forEach((route) => {
    const subRoutes: Array<any> = [];
    if (route.sub_views) {
      route.sub_views.forEach((subRoute: any) => {
        subRoutes.push({
          title: subRoute.title.trim(),
          route: subRoute.name.trim(),
        });
      });
    }
    parsedData.push({
      title: route.title.trim(),
      route: route.name.trim(),
      children: subRoutes,
    });
  });

  return parsedData;
};

const Login = () => {
  const navigate = useNavigate();
  const { refetch: authenticate, isLoading: isAuthenticating } =
    useAuthentication((response) => {
      const { brands, fullname, menues, permissions, userid, username } =
        response;
      const routes = parserUserMenus(menues);
      const sortedBrands = sortBrands(brands);
      storage.setAllowedBrands(sortedBrands);
      storage.setAllowedRoutes(routes);
      storage.setToken(secretToken);
      storage.setPermissions(permissions);
      storage.setUserId(userid);
      storage.setUserFullName(fullname);
      storage.setUserEmail(username);
      navigate('/', { replace: true });
    });
  const { mutate: login, isLoading: isGettingToken } = useToken(authenticate);

  return (
    <Form onFinish={login}>
      <FormItem
        label="User Name"
        name="username"
        rules={buildFormRules({ required: true, email: true, max: 50 })}
      >
        <Input />
      </FormItem>
      <FormItem
        label="Password"
        name="password"
        rules={buildFormRules({ required: true, min: 3, max: 25 })}
      >
        <PasswordInput />
      </FormItem>
      <FormItem>
        <Button
          htmlType="submit"
          type="primary"
          block={true}
          loading={isGettingToken || isAuthenticating}
        >
          Login
        </Button>
      </FormItem>
      <div className="text-center">
        <Link to="/reset-password">Forgot Password</Link>
      </div>
    </Form>
  );
};

export default Login;

import { useRoutes } from 'react-router-dom';

import { secretToken } from '../constants';
import storage from '../utils/storage';

import ProtectedRoutes from './ProtectedRoutes';
import { PublicRoutes } from './public';

const AppRoutes = () => {
  const token = storage.getToken();
  const routes = token == secretToken ? ProtectedRoutes() : PublicRoutes;
  const element = useRoutes(routes);

  return <>{element}</>;
};

export default AppRoutes;

import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AppContext } from 'src/context';
import useAllowedBrands from 'src/hooks/useAllowedBrands';
import useAllowedRoutes from 'src/hooks/useAllowedRoutes';
import { navigations } from 'src/routes/paths';

import { Button } from './Generals';
import { Option, Select } from './Inputs';
import { Breadcrumb, BreadcrumbItem, PageHeader } from './Navigations';

const SelectBrand = () => {
  const { brands } = useAllowedBrands();
  const { brandId, setBrandId } = useContext(AppContext);

  return (
    <Select
      className="input-select-brand"
      value={brandId}
      onChange={setBrandId}
    >
      {brands.map(({ id, name }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

const ShowModal = () => {
  const { showBulkOrdersJobModal } = useContext(AppContext);

  return (
    <Button type="primary" onClick={showBulkOrdersJobModal}>
      View Orders Job
    </Button>
  );
};

const Header = () => {
  const location = useLocation();
  const { firstRoute } = useAllowedRoutes();

  const paths = location.pathname.split('/');
  const [homePath, mainPath, childPath] = paths;
  const mainRoute =
    mainPath && navigations.find((_route) => _route.route == mainPath);
  const childRoute =
    mainRoute && mainRoute.children.find((_child) => _child.route == childPath);
  const pageTitle = mainRoute ? mainRoute.title : '';

  const breadcrumb = (
    <Breadcrumb>
      {homePath == '' && (
        <BreadcrumbItem>
          <Link to={firstRoute.route}>Home</Link>
        </BreadcrumbItem>
      )}
      {mainRoute && <BreadcrumbItem>{mainRoute.title}</BreadcrumbItem>}
      {childRoute && <BreadcrumbItem>{childRoute.title}</BreadcrumbItem>}
    </Breadcrumb>
  );
  const extra = [<ShowModal key={2} />, <SelectBrand key={1} />];

  return (
    <PageHeader
      className="site-header"
      title={pageTitle}
      breadcrumb={breadcrumb}
      extra={extra}
    />
  );
};

export default Header;

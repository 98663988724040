import {
  Badge as AntdBadge,
  BadgeProps,
  Card as AntdCard,
  CardProps,
  Collapse as AntdCollapse,
  CollapseProps,
  Comment as AntdComment,
  CommentProps,
  Descriptions as AntdDescriptions,
  DescriptionsProps,
  Empty as AntdEmpty,
  EmptyProps,
  List as AntdList,
  ListProps,
  Statistic as AntdStatistic,
  StatisticProps,
  Table as AntdTable,
  TableProps,
  Tabs as AntdTabs,
  TabsProps,
  Tag as AntdTag,
  TagProps,
  Timeline as AntdTimeline,
  TimelineProps,
  Tooltip,
} from 'antd';
import React from 'react';
import {
  default as ReactNumberFormat,
  NumberFormatProps,
} from 'react-number-format';

import infoIcon from 'src/assets/information.svg';

type ExtendedBadgeProps = BadgeProps & {
  children?: React.ReactNode;
};

type ExtendedCollapseProps = CollapseProps & {
  children?: React.ReactNode;
};

type ExtendedTimelineProps = TimelineProps & {
  children?: React.ReactNode;
};

type TextProps = {
  text: string;
  className?: string;
};

type InfoTooltipProps = {
  info: string;
};

export const Badge = (props: ExtendedBadgeProps) => {
  const { showZero = true, children } = props;
  return (
    <AntdBadge showZero={showZero} {...props}>
      {children}
    </AntdBadge>
  );
};

export const Card = (props: CardProps) => {
  const { children } = props;
  return <AntdCard {...props}>{children}</AntdCard>;
};

export const Collapse = (props: ExtendedCollapseProps) => {
  const { children } = props;
  return <AntdCollapse {...props}>{children}</AntdCollapse>;
};
export const { Panel: CollapsePanel } = AntdCollapse;

export const Comment = (props: CommentProps) => {
  const { children } = props;
  return <AntdComment {...props}>{children}</AntdComment>;
};

export const Descriptions = (props: DescriptionsProps) => {
  const { layout = 'vertical', children } = props;
  return (
    <AntdDescriptions layout={layout} {...props}>
      {children}
    </AntdDescriptions>
  );
};
export const { Item: DescriptionsItem } = AntdDescriptions;

export const Empty = (props: EmptyProps) => {
  return <AntdEmpty {...props} />;
};

export const InfoTooltip = ({ info }: InfoTooltipProps) => {
  return (
    <Tooltip title={info}>
      <img src={infoIcon} alt="info" />
    </Tooltip>
  );
};

export const List = (props: ListProps<any>) => {
  return <AntdList {...props} />;
};
export const { Item: ListItem } = AntdList;

export const NumberFormat = (props: NumberFormatProps) => {
  const { displayType = 'text', thousandSeparator = true } = props;
  const className = displayType != 'text' ? 'input-number-format' : '';

  return (
    <ReactNumberFormat
      className={className}
      displayType={displayType}
      thousandSeparator={thousandSeparator}
      {...props}
    />
  );
};

export const Statistic = (props: StatisticProps) => {
  return <AntdStatistic {...props} />;
};

export const Tabs = (props: TabsProps) => {
  const { children } = props;
  return <AntdTabs {...props}>{children}</AntdTabs>;
};
export const { TabPane } = AntdTabs;

export const Table = (props: TableProps<any>) => {
  const { pagination = false, scroll = { x: true } } = props;
  return <AntdTable pagination={pagination} scroll={scroll} {...props} />;
};

export const Tag = (props: TagProps) => {
  const { children } = props;
  return <AntdTag {...props}>{children}</AntdTag>;
};

export const Text = ({ text, className = '' }: TextProps) => {
  return <div className={className}>{text}</div>;
};

export const Timeline = (props: ExtendedTimelineProps) => {
  const { mode = 'left', reverse = false, children } = props;
  return (
    <AntdTimeline mode={mode} reverse={reverse} {...props}>
      {children}
    </AntdTimeline>
  );
};
export const { Item: TimelineItem } = AntdTimeline;

import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { Divider, Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import useAllowedRoutes from 'src/hooks/useAllowedRoutes';
import useWindowSize from 'src/hooks/useWindowSize';
import { navigations } from 'src/routes/paths';

import { Drawer } from './Feedbacks';
import { Button } from './Generals';
import { Close, MenuIcon } from './Icons';

import 'src/styles/sidebar.css';

const Sidebar = () => {
  const { Sider } = Layout;
  const { SubMenu } = Menu;

  const { isRouteAllowed, isSubRouteAllowed } = useAllowedRoutes();

  const location = useLocation();

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[]>([location.pathname]);

  const [width] = useWindowSize();
  const selectedKeys: string[] = [location.pathname];

  const components = {
    hideCollapse: RightCircleFilled,
    showCollapse: LeftCircleFilled,
  };

  const IconComponent = components[collapsed ? 'hideCollapse' : 'showCollapse'];

  const handleSideBarCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleOpenSubMenu = (keys: string[]) => {
    const latestOpenSubMenu = keys[keys.length - 1];
    setOpenKeys([latestOpenSubMenu]);
  };

  const onClose = () => {
    setIsVisible(false);
  };

  const openMenu = () => {
    setIsVisible(true);
  };

  const AppMenu = (
    <Menu
      mode="inline"
      className="menu-items left-menu-scroll"
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      onOpenChange={handleOpenSubMenu}
    >
      {navigations.map(({ route, title, icon, children }) =>
        children.length
          ? isRouteAllowed(route) && (
              <SubMenu key={route} icon={icon} title={title}>
                {children.map(
                  ({ route: childRoute, title: childTitle }) =>
                    isSubRouteAllowed(childRoute) && (
                      <Menu.Item key={childRoute}>
                        <Link to={`${route}/${childRoute}`}>{childTitle}</Link>
                      </Menu.Item>
                    )
                )}
              </SubMenu>
            )
          : isRouteAllowed(route) && (
              <Menu.Item key={route} icon={icon}>
                <Link to={route}>{title}</Link>
              </Menu.Item>
            )
      )}
    </Menu>
  );

  return width > 768 ? (
    <Sider
      width={200}
      trigger={null}
      className="side-bar side-bar-nav"
      collapsed={collapsed}
      collapsible={true}
    >
      <IconComponent
        className="side-bar-icon"
        onClick={handleSideBarCollapse}
      />
      <div className="side-header-menu">MENU</div>
      {AppMenu}
      <Divider />
      <div className="side-footer">
        <>&#169</>
        XStak Inc.
      </div>
    </Sider>
  ) : (
    <>
      <Drawer
        placement="left"
        onClose={onClose}
        visible={isVisible}
        className="drawer"
        closeIcon={
          <Button
            shape="circle"
            type="primary"
            size="large"
            icon={Close}
            className="menu-button"
            onClick={openMenu}
          />
        }
      >
        {AppMenu}
      </Drawer>
      <Button
        shape="circle"
        type="primary"
        size="large"
        icon={MenuIcon}
        className="menu-button"
        onClick={openMenu}
      />
    </>
  );
};

export default Sidebar;

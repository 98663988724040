export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:1122';

export const pageSizeOptions = [10, 20, 30, 40, 50, 100, 250, 500, 1000];

export const secretToken = 'authenticated';

export const zones = [
  '-12:00',
  '-11:00',
  '-10:00',
  '-09:30',
  '-09:00',
  '-08:00',
  '-07:00',
  '-06:00',
  '-05:00',
  '-04:30',
  '-04:00',
  '-03:30',
  '-03:00',
  '-02:00',
  '-01:00',
  '00:00',
  '+01:00',
  '+02:00',
  '+03:00',
  '+03:30',
  '+04:00',
  '+04:30',
  '+05:00',
  '+05:30',
  '+05:45',
  '+06:00',
  '+06:30',
  '+07:00',
  '+08:00',
  '+08:45',
  '+09:00',
  '+09:30',
  '+10:00',
  '+10:30',
  '+11:00',
  '+11:30',
  '+12:00',
  '+12:45',
  '+13:00',
  '+14:00',
];

import { Button as AntdButton, ButtonProps } from 'antd';
import React from 'react';

import usePermissions from 'src/hooks/usePermissions';

type ExtendedBtnProps = ButtonProps & {
  permissions: Array<string>;
};

export const Button = (props: ButtonProps) => {
  const { children } = props;
  return <AntdButton {...props}>{children}</AntdButton>;
};

export const PermissionButton = (props: ExtendedBtnProps) => {
  const { permissions, children } = props;
  const isPermitted = usePermissions(permissions);
  return <>{isPermitted && <AntdButton {...props}>{children}</AntdButton>}</>;
};

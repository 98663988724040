import {
  Control,
  Crown,
  Dashboard,
  FileExclamation,
  FileSync,
  Inbox,
  Reconciliation,
  Setting,
  Shop,
  ShoppingCart,
  Sound,
  Tool,
} from 'src/components/Icons';

export const mainPaths = {
  DASHBOARD: 'dashboard',
  ORDERS: 'orders',
  INVENTORY: 'inventory',
  DATA_DUMP: 'data-dump',
  MONITORING: 'monitoring',
  RECONCILIATION: 'reconciliation',
  BLACKLIST: 'blacklist',
  UTILITIES: 'utilities',
  SHOPCAST: 'shopcast',
  MARKETING: 'marketing',
  SETTINGS: 'settings',
  ADMIN_PANEL: 'super-admin-panel',
};

export const dashboardPaths = {
  SALES_INFORMATION: 'sales-information',
  ANALYTICS_DASHBOARD: 'analytics-dashboard',
};

export const dataDumpPaths = {
  ORDER: 'order-dump',
  INVENTORY: 'inventory-dump',
  SKU_SOLD: 'sku-sold-dump',
};

export const inventoryPaths = {
  DEFAULT: 'default-feed',
  PRICE: 'price-feed',
  QUANTITY: 'quantity-feed',
  SYNC_WEB_STORE: 'sync-web-store',
};

export const monitoringPaths = {
  ORDER: 'order-monitoring',
};

export const reconciliationPaths = {
  MONTHLY_RECONCILIATION: 'monthly-reconciliation',
};

export const blacklistPaths = {
  BLACKLISTED_CUSTOMER: 'blacklisted-customers',
};

export const utilitiesPaths = {
  SKU_SEARCH: 'sku-search',
  INVENTORY_UPDATE_STATUS: 'inventory-update-status',
  REPLENISHMENT_REPORT: 'replenishment-report',
  PRICE_UPDATE_STATUS: 'price-update-status',
  BULK_PROCESS_JOBS: 'bulk-process-jobs',
  SCHEDULERS: 'schedulers',
};

export const marketingPaths = {
  CUSTOMER_STATISTICS: 'customer-statistics',
};

export const settingsPaths = {
  GENERAL: 'general',
  SALES_CHANNELS: 'sales-channels',
  LOCATIONS: 'locations',
  COURIERS: 'couriers',
  USERS: 'users',
  MARKETING_STATISTICS: 'marketing-statistics',
  AUTO_ASSIGNING: 'auto-assigning',
  AUTO_BOOKING: 'auto-booking',
  OMNI_SCHEDULER_SETTINGS: 'omni-scheduler-settings',
  SLOTTING: 'slotting',
  IVR: 'ivr',
  TAGS: 'tags',
};

export const adminPanelPaths = {
  BRANDS: 'all-brands',
  USERS: 'all-users',
  USER_ACTIONS_AUDIT: 'user-actions-audit',
};

export const navigations = [
  {
    route: mainPaths.DASHBOARD,
    title: 'Dashboard',
    icon: Dashboard,
    children: [
      {
        route: dashboardPaths.SALES_INFORMATION,
        title: 'Sales Information',
      },
      {
        route: dashboardPaths.ANALYTICS_DASHBOARD,
        title: 'Analytics Dashboard',
      },
    ],
  },
  {
    route: mainPaths.ORDERS,
    title: 'Orders',
    icon: ShoppingCart,
    children: [],
  },
  {
    route: mainPaths.DATA_DUMP,
    title: 'Data Dump',
    icon: Inbox,
    children: [
      {
        route: dataDumpPaths.ORDER,
        title: 'Order Dump',
      },
      {
        route: dataDumpPaths.INVENTORY,
        title: 'Inventory Dump',
      },
      {
        route: dataDumpPaths.SKU_SOLD,
        title: 'Sku Sold Dump',
      },
    ],
  },
  {
    route: mainPaths.INVENTORY,
    title: 'Inventory',
    icon: Shop,
    children: [
      {
        route: inventoryPaths.DEFAULT,
        title: 'Default Inventory Update',
      },
      {
        route: inventoryPaths.PRICE,
        title: 'Price Update',
      },
      {
        route: inventoryPaths.QUANTITY,
        title: 'Quantity Update',
      },
      {
        route: inventoryPaths.SYNC_WEB_STORE,
        title: 'Sync Web Store',
      },
    ],
  },
  {
    route: mainPaths.MONITORING,
    title: 'Monitoring',
    icon: FileSync,
    children: [
      {
        route: monitoringPaths.ORDER,
        title: 'Order Monitoring',
      },
    ],
  },
  {
    route: mainPaths.RECONCILIATION,
    title: 'Reconciliation',
    icon: Reconciliation,
    children: [
      {
        route: reconciliationPaths.MONTHLY_RECONCILIATION,
        title: 'Monthly Reconciliation',
      },
    ],
  },
  {
    route: mainPaths.BLACKLIST,
    title: 'Blacklist',
    icon: FileExclamation,
    children: [
      {
        route: blacklistPaths.BLACKLISTED_CUSTOMER,
        title: 'Blacklisted Customers',
      },
    ],
  },
  {
    route: mainPaths.UTILITIES,
    title: 'Utilities',
    icon: Control,
    children: [
      {
        route: utilitiesPaths.SKU_SEARCH,
        title: 'Sku Search',
      },
      {
        route: utilitiesPaths.INVENTORY_UPDATE_STATUS,
        title: 'Inventory Update Status',
      },
      {
        route: utilitiesPaths.REPLENISHMENT_REPORT,
        title: 'Replenishment Report',
      },
      {
        route: utilitiesPaths.PRICE_UPDATE_STATUS,
        title: 'Price Update Status',
      },
      {
        route: utilitiesPaths.BULK_PROCESS_JOBS,
        title: 'Bulk Process Jobs',
      },
      {
        route: utilitiesPaths.SCHEDULERS,
        title: 'Schedulers',
      },
    ],
  },
  {
    route: mainPaths.SHOPCAST,
    title: 'Shopcast',
    icon: Crown,
    children: [],
  },
  {
    route: mainPaths.MARKETING,
    title: 'Marketing',
    icon: Sound,
    children: [
      {
        route: marketingPaths.CUSTOMER_STATISTICS,
        title: 'Customer Statistics',
      },
    ],
  },
  {
    route: mainPaths.SETTINGS,
    title: 'Settings',
    icon: Setting,
    children: [
      {
        route: settingsPaths.GENERAL,
        title: 'General',
      },
      {
        route: settingsPaths.SALES_CHANNELS,
        title: 'Sales Channels',
      },
      {
        route: settingsPaths.LOCATIONS,
        title: 'Locations',
      },
      {
        route: settingsPaths.COURIERS,
        title: 'Couriers',
      },
      {
        route: settingsPaths.USERS,
        title: 'Users',
      },
      {
        route: settingsPaths.MARKETING_STATISTICS,
        title: 'Marketing Statistics',
      },
      {
        route: settingsPaths.AUTO_ASSIGNING,
        title: 'Auto Assigning',
      },
      {
        route: settingsPaths.AUTO_BOOKING,
        title: 'Auto Booking',
      },
      {
        route: settingsPaths.OMNI_SCHEDULER_SETTINGS,
        title: 'Omni Scheduler Settings',
      },
      {
        route: settingsPaths.SLOTTING,
        title: 'Slotting',
      },
      {
        route: settingsPaths.IVR,
        title: 'Ivr',
      },
      {
        route: settingsPaths.TAGS,
        title: 'Tags',
      },
    ],
  },
  {
    route: mainPaths.ADMIN_PANEL,
    title: 'Admin Panel',
    icon: Tool,
    children: [
      {
        route: adminPanelPaths.BRANDS,
        title: 'All Brands',
      },
      {
        route: adminPanelPaths.USERS,
        title: 'all-users',
      },
      {
        route: adminPanelPaths.USER_ACTIONS_AUDIT,
        title: 'User Actions Audit',
      },
    ],
  },
];

import {
  Col,
  ColProps,
  Divider as AntdDivider,
  DividerProps,
  Row as AntdRow,
  Layout as AntdLayout,
  LayoutProps,
  RowProps,
  SpaceProps,
  Space as AntdSpace,
} from 'antd';
import React from 'react';

type ContainerProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  className?: string;
  children: React.ReactNode;
};

type ExtendedColProps = ColProps & {
  size?: number;
  children?: React.ReactNode;
  responsive?: boolean;
};

export const Column = (props: ExtendedColProps) => {
  const {
    size = 24,
    responsive = true,
    xs = responsive ? 24 : size,
    sm = responsive ? 24 : size,
    md = size,
    lg = size,
    xl = size,
    xxl = size,
    children,
  } = props;

  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} {...props}>
      {children}
    </Col>
  );
};

export const Container = ({ size, className, children }: ContainerProps) => {
  let classes = '';
  if (className) classes += className;
  if (size) classes += ` container-${size}`;

  return <div className={classes}>{children}</div>;
};

export const Divider = (props: DividerProps) => {
  const { type = 'horizontal' } = props;
  return <AntdDivider type={type} {...props} />;
};

export const Layout = (props: LayoutProps) => {
  const { children } = props;
  return <AntdLayout {...props}>{children}</AntdLayout>;
};
export const {
  Header: LayoutHeader,
  Sider: LayoutSider,
  Content: LayoutContent,
} = AntdLayout;

export const Row = (props: RowProps) => {
  const { gutter = [8, 8], children } = props;
  return (
    <AntdRow gutter={gutter} {...props}>
      {children}
    </AntdRow>
  );
};

export const Space = (props: SpaceProps) => {
  const { direction = 'horizontal', children } = props;
  return (
    <AntdSpace direction={direction} {...props}>
      {children}
    </AntdSpace>
  );
};

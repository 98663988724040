import Axios from 'axios';

import { BASE_URL } from 'src/constants';
import storage from 'src/utils/storage';

const axios = Axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const { status, config } = error.response;
    if (
      status == 401 &&
      config.url != 'https://shopcast.shopdev.co/auth/signin'
    ) {
      storage.clearStorage();
      window.location.replace('/login');
    }

    return Promise.reject(error.response);
  }
);

export default axios;

import { useCallback, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

import {
  getSelectedOrderIds,
  removeSelectedOrderIds,
} from 'src/api/SelectedOrderIds';
import storage from 'src/utils/storage';
import { showErrorToast } from 'src/utils/toast';

type SocketResponse = {
  isFinal: 'true' | 'false';
  jobId: string;
  siteOrderId: string;
  status: 'true' | 'false';
};

const useBulkOrdersJobSocket = () => {
  const [socket, setSocket] = useState<Socket>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bulkOrdersJob, setBulkOrdersJob] = useState(new Map<string, string>());

  const showModal = useCallback(() => {
    if (bulkOrdersJob.size) setIsModalVisible(true);
    else showErrorToast('No bulk orders job in progress');
  }, []);

  const hideModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const triggerBulkOrdersJob = useCallback(() => {
    const siteOrderIds = getSelectedOrderIds();
    if (!siteOrderIds.length) return;

    const job = new Map<string, string>();
    siteOrderIds.forEach((id) => job.set(id, 'pending'));
    setBulkOrdersJob(job);
    setIsModalVisible(true);
  }, []);

  const updateBulkOrdersJob = useCallback((key: string, value: string) => {
    setBulkOrdersJob((prevState) => {
      return prevState.has(key) ? prevState.set(key, value) : prevState;
    });
  }, []);

  const timeoutBulkOrdersJob = useCallback(() => {
    removeSelectedOrderIds();
    setBulkOrdersJob((prevState) => {
      const map = new Map<string, string>(prevState);
      map.forEach((value, key) => {
        if (value == 'pending') {
          map.set(key, 'timeout');
        }
      });
      return map;
    });
    socket?.removeAllListeners(storage.getUserEmail());
  }, [socket]);

  const clearBulkOrdersJob = useCallback(() => {
    setIsModalVisible(false);
    removeSelectedOrderIds();
    setBulkOrdersJob(new Map<string, string>());
  }, []);

  useEffect(() => {
    const newSocket = io('/', {
      query: { username: storage.getUserEmail() },
    });
    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  useEffect(() => {
    if (!bulkOrdersJob.size) return;

    const timeoutCallback = setTimeout(() => {
      timeoutBulkOrdersJob();
    }, 20000);

    socket?.on(storage.getUserEmail(), (data: SocketResponse) => {
      clearTimeout(timeoutCallback);

      const isFinal = JSON.parse(data.isFinal);
      const status = JSON.parse(data.status);
      const { siteOrderId } = data;

      if (isFinal || siteOrderId == '_final') {
        timeoutBulkOrdersJob();
      } else {
        const value = status ? 'success' : 'error';
        updateBulkOrdersJob(siteOrderId, value);
      }
    });
  }, [socket, bulkOrdersJob, timeoutBulkOrdersJob, updateBulkOrdersJob]);

  return {
    isModalVisible,
    showModal,
    hideModal,
    bulkOrdersJob,
    triggerBulkOrdersJob,
    clearBulkOrdersJob,
  };
};

export default useBulkOrdersJobSocket;

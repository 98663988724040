import { Form as AntdForm, FormProps, FormItemProps } from 'antd';
import React from 'react';

import { showErrorToast } from 'src/utils/toast';

const handleFailedFinish = () => showErrorToast('Fill in the fields correctly');

export const Form = (props: FormProps) => {
  const {
    layout = 'vertical',
    size = 'middle',
    autoComplete = 'off',
    onFinishFailed,
    children,
  } = props;
  return (
    <AntdForm
      layout={layout}
      onFinishFailed={onFinishFailed ? onFinishFailed : handleFailedFinish}
      autoComplete={autoComplete}
      size={size}
      {...props}
    >
      {children}
    </AntdForm>
  );
};

export const FormItem = (props: FormItemProps) => {
  const { children } = props;
  return <AntdForm.Item {...props}>{children}</AntdForm.Item>;
};

import { Navigate, Route, Routes } from 'react-router-dom';

import Login from '../components/Login';
import ResetPassword from '../components/ResetPassword';

const AuthRoutes = () => {
  return (
    <div className="auth-container">
      <article className="auth-content">
        <section className="img-section">
          <img
            className="auth-landing-img"
            src="/public/login_landing.jpg"
            alt="login"
          />
        </section>
        <section className="form-section">
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </section>
      </article>
    </div>
  );
};

export default AuthRoutes;

import React, { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import Header from 'src/components/Header';
import { Layout, LayoutContent } from 'src/components/Layouts';
import Loading from 'src/components/Loading';
import Sidebar from 'src/components/Sidebar';
import useAllowedRoutes from 'src/hooks/useAllowedRoutes';

import { mainPaths } from './paths';

const AdminPanel = lazy(() => import('src/features/admin-panel'));
const Blacklist = lazy(() => import('src/features/blacklist'));
const Dashboard = lazy(() => import('src/features/dashboard'));
const DataDump = lazy(() => import('src/features/data-dump'));
const Inventory = lazy(() => import('src/features/inventory'));
const Marketing = lazy(() => import('src/features/marketing'));
const Monitoring = lazy(() => import('src/features/monitoring'));
const Orders = lazy(() => import('src/features/orders'));
const Settings = lazy(() => import('src/features/settings'));
const Reconciliation = lazy(() => import('src/features/reconciliation'));
const NotFound = lazy(() => import('src/components/NotFound'));
const Utilities = lazy(() => import('src/features/utilities'));

const App = () => {
  return (
    <Layout className="site-layout">
      <Sidebar />
      <Layout className="site-layout-container">
        <Header />
        <LayoutContent className="site-layout-content">
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </LayoutContent>
      </Layout>
    </Layout>
  );
};

const pathElements = [
  {
    path: mainPaths.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: mainPaths.ORDERS,
    element: <Orders />,
  },
  {
    path: mainPaths.INVENTORY,
    element: <Inventory />,
  },
  {
    path: mainPaths.DATA_DUMP,
    element: <DataDump />,
  },
  {
    path: mainPaths.MONITORING,
    element: <Monitoring />,
  },
  {
    path: mainPaths.RECONCILIATION,
    element: <Reconciliation />,
  },
  {
    path: mainPaths.BLACKLIST,
    element: <Blacklist />,
  },
  {
    path: mainPaths.UTILITIES,
    element: <Utilities />,
  },
  {
    path: mainPaths.SHOPCAST,
    element: <div>SHOPCAST</div>,
  },
  {
    path: mainPaths.MARKETING,
    element: <Marketing />,
  },
  {
    path: mainPaths.SETTINGS,
    element: <Settings />,
  },
  {
    path: mainPaths.ADMIN_PANEL,
    element: <AdminPanel />,
  },
];

const ProtectedRoutes = () => {
  const { isRouteAllowed } = useAllowedRoutes();
  const children: Array<any> = [];
  pathElements.forEach((route) => {
    const { path, element } = route;
    if (isRouteAllowed(path)) children.push({ path: `${path}/*`, element });
  });
  if (children.length) {
    const defaultPath = children[0].path.split('/')[0];
    children.push({
      path: '',
      element: <Navigate to={defaultPath} replace={true} />,
    });
  }
  children.push({ path: '*', element: <NotFound /> });

  return [
    {
      path: '',
      element: <App />,
      children: children,
    },
  ];
};

export default ProtectedRoutes;

import { Result } from './Feedbacks';
import { Button } from './Generals';
import { AuthFailedIcon, BadReqIcon } from './Icons';

const errorGeneral = 'Sorry, something went wrong.';
const error400 = 'Error in your request.';
const error401 = 'Authentication failed.';
const error403 = 'Access denied, ask administrator to grant access.';
const error404 = 'Resource not found.';

const Reload = (
  <Button type="primary" onClick={() => window.location.reload()}>
    Reload
  </Button>
);
const Login = (
  <Button type="default" onClick={() => window.location.replace('/login')}>
    Sign in
  </Button>
);

const ErrorFallback = (props: any) => {
  const { error } = props;
  const { status: statusCode } = error;

  const antdProps: any = {
    title: statusCode,
    status: 'error',
    extra: Reload,
  };

  switch (statusCode) {
    case 400:
      antdProps.icon = BadReqIcon;
      antdProps.subTitle = error400;
      break;
    case 401:
      antdProps.icon = AuthFailedIcon;
      antdProps.subTitle = error401;
      antdProps.extra = Login;
      break;
    case 403:
      antdProps.status = statusCode;
      antdProps.subTitle = error403;
      break;
    case 404:
      antdProps.status = statusCode;
      antdProps.subTitle = error404;
      break;
    case 500:
      antdProps.status = statusCode;
      antdProps.subTitle = errorGeneral;
      break;
    default:
      antdProps.subTitle = errorGeneral;
      break;
  }

  return <Result {...antdProps} />;
};

export default ErrorFallback;

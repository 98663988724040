import { emailRegExp } from './regExp';

const requiredRule = { required: true, message: 'Field is required.' };
const getMinRule = (val: number) => ({
  min: val,
  message: `Minimum ${val} characters allowed.`,
});
const getMaxRule = (val: number) => ({
  max: val,
  message: `Maximum ${val} characters allowed.`,
});
const emailRule = {
  pattern: emailRegExp,
  message: 'Enter a valid email address.',
};

type BuilderProps = {
  required?: boolean;
  min?: number;
  max?: number;
  email?: boolean;
};

export const buildFormRules = ({
  required = false,
  min = -1,
  max = -1,
  email = false,
}: BuilderProps): Array<any> => {
  const rules = [];

  if (required) rules.push(requiredRule);
  if (min >= 0) rules.push(getMinRule(min));
  if (max >= 0) rules.push(getMaxRule(max));
  if (email) rules.push(emailRule);

  return rules;
};

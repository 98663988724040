import { useEffect, useState } from 'react';

import storage from 'src/utils/storage';

type Props = Array<string>;

const usePermissions = (permissions: Props) => {
  const [isPermitted, setIsPermitted] = useState(false);

  useEffect(() => {
    const userPermissions = storage.getPermissions();
    const res = permissions.some((permission: string) =>
      userPermissions.includes(permission)
    );
    setIsPermitted(res);
  }, [permissions]);

  return isPermitted;
};

export default usePermissions;

import { useEffect, useState } from 'react';

import { RouteType } from 'src/types';
import storage from 'src/utils/storage';

const useAllowedRoutes = () => {
  const [routePaths, setRoutePaths] = useState<Array<string>>([]);
  const [subRoutePaths, setSubRoutePaths] = useState<Array<string>>([]);
  const [firstRoute, setFirstRoute] = useState<RouteType>({
    route: 'not-found',
    title: 'Not Found',
    children: [],
  });

  useEffect(() => {
    const userRoutes = storage.getUserRoutes();

    const allowedFirstRoute = userRoutes[0];
    setFirstRoute(allowedFirstRoute);

    const allowedRoutes = userRoutes.map((it: RouteType) => it.route);
    setRoutePaths(allowedRoutes);

    const allowedSubRoutes: Array<string> = [];
    userRoutes.forEach((route: RouteType) => {
      const { children } = route;
      if (children && children.length) {
        children.forEach((child) => allowedSubRoutes.push(child.route));
      }
    });
    setSubRoutePaths(allowedSubRoutes);
  }, []);

  const isRouteAllowed = (route: string) => {
    return routePaths.includes(route);
  };

  const isSubRouteAllowed = (subRoute: string) => {
    return subRoutePaths.includes(subRoute);
  };

  return { firstRoute, isRouteAllowed, isSubRouteAllowed };
};

export default useAllowedRoutes;

import { QueryClient, DefaultOptions } from 'react-query';

const errorHandler = (error: any) => {
  const { status } = error;
  return status == 401 || status == 404 || status == 503 || status == 504;
};

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: (error: any) => errorHandler(error),
    refetchOnWindowFocus: false,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

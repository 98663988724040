import { useMutation, useQuery } from 'react-query';

import axios from 'src/lib/axios';
import { notifyError } from 'src/utils/notification';

const notifyTitle = 'Authentication';
const errorMsg = 'Cannot authenticate at the moment';
const credentialsErrorMsg = 'Wrong Credentials';
const expiryErrorMsg =
  'Your password has expired, please check your email for link to reset the password';

const getToken = (payload: any) => axios.post('/api/oauth/token', payload);

const authenticate = () => axios.get('/api/users/authenticate');

export const useToken = (onSuccess: () => void) =>
  useMutation(['userToken'], (payload: any) => getToken(payload), {
    onSuccess: onSuccess,
    onError: (error: any) => {
      const msg =
        error.STATUS === 'LOCKED' ? expiryErrorMsg : credentialsErrorMsg;
      notifyError(notifyTitle, msg);
    },
  });

export const useAuthentication = (onSuccess: (data: any) => void) =>
  useQuery(['userAuthentication'], () => authenticate(), {
    enabled: false,
    onSuccess: onSuccess,
    onError: () => {
      notifyError(notifyTitle, errorMsg);
    },
  });

export const generateCsvByKeys = (
  titles: Array<string>,
  keys: Array<string>,
  dataset: Array<any>,
  fileName: string
) => {
  let rows = '';
  titles.forEach(
    (title, index) =>
      (rows += titles.length - 1 === index ? `${title}\n` : `${title},  `)
  );
  dataset.forEach((data) => {
    keys.forEach(
      (key, i) =>
        (rows += keys.length - 1 === i ? `${data[key]}\n` : `${data[key]},  `)
    );
  });
  const csvContent = 'data:text/csv;charset=utf-8,';
  const encodedUri = encodeURI(csvContent + rows);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName + '.csv');
  document.body.appendChild(link);
  link.click();
};

export const generateZipByBinaryData = (
  binaryData: any,
  dataType: string,
  fileName: string
) => {
  const blobData = [];
  blobData.push(binaryData);
  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(
    new Blob(blobData, { type: dataType })
  );
  downloadLink.setAttribute('download', fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

import { notification } from 'antd';

const duration = 10;

export const notify = (title: string, message: string) =>
  notification.open({
    message: title,
    description: message,
    duration: duration,
    placement: 'topRight',
  });

export const notifySuccess = (title: string, message: string) =>
  notification.success({
    message: title,
    description: message,
    duration: duration,
    placement: 'topRight',
  });

export const notifyError = (title: string, message: string) =>
  notification.error({
    message: title,
    description: message,
    duration: duration,
    placement: 'topRight',
  });

export const notifyInfo = (title: string, message: string) =>
  notification.info({
    message: title,
    description: message,
    duration: duration,
    placement: 'topRight',
  });

export const notifyWarning = (title: string, message: string) =>
  notification.warning({
    message: title,
    description: message,
    duration: duration,
    placement: 'topRight',
  });

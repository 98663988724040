import React, { useState } from 'react';

import BulkOrdersJobStatusModal from '../components/BulkOrdersJobStatusModal';
import { AppContext } from '../context';
import useBulkOrdersJobSocket from '../hooks/useBulkOrdersJobSocket';
import storage from '../utils/storage';

type Props = {
  children: React.ReactNode;
};

const ContextProvider = ({ children }: Props) => {
  const brand = storage.getAllowedFirstBrand();
  const brand_id = brand ? brand.brand_id : -1;
  const [brandId, setBrandId] = useState(brand_id);

  const {
    isModalVisible,
    hideModal,
    showModal,
    bulkOrdersJob,
    triggerBulkOrdersJob,
    clearBulkOrdersJob,
  } = useBulkOrdersJobSocket();

  return (
    <AppContext.Provider
      value={{
        brandId,
        setBrandId,
        triggerBulkOrdersJob,
        showBulkOrdersJobModal: showModal,
      }}
    >
      {children}
      <BulkOrdersJobStatusModal
        isVisible={isModalVisible}
        hideModal={hideModal}
        bulkOrdersJob={bulkOrdersJob}
        clearBulkOrdersJob={clearBulkOrdersJob}
      />
    </AppContext.Provider>
  );
};

export default ContextProvider;

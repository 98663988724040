import {
  AutoComplete as AntdAutoComplete,
  AutoCompleteProps,
  Checkbox as AntdCheckbox,
  CheckboxProps,
  Input as AntdInput,
  InputProps,
  InputNumber as AntdInputNumber,
  InputNumberProps,
  Switch as AntdSwitch,
  SwitchProps,
  Select as AntdSelect,
  SelectProps,
  Upload as AntdUpload,
  UploadProps,
} from 'antd';
import React, { useState } from 'react';

import { showErrorToast } from 'src/utils/toast';

import { Button } from './Generals';
import { CloudUpload } from './Icons';
import { Column, Row } from './Layouts';

type ExtendedUploadProps = UploadProps & {
  children: React.ReactNode;
};

type UploadSpreadSheetProps = {
  isUploading: boolean;
  uploadFile: (_file: any) => void;
  allowCsvSheet?: boolean;
  allowExcelSheet?: boolean;
  children?: React.ReactNode;
};

const csvSheetType = 'text/csv';
const xlsSheetType = 'application/vnd.ms-excel';
const xlsxSheetType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const AutoComplete = (props: AutoCompleteProps) => {
  const { children } = props;
  return <AntdAutoComplete {...props}>{children}</AntdAutoComplete>;
};

export const Checkbox = (props: CheckboxProps) => {
  const { children } = props;
  return <AntdCheckbox {...props}>{children}</AntdCheckbox>;
};

export const Input = (props: InputProps) => {
  return <AntdInput {...props} />;
};
export const {
  Group: InputGroup,
  Search: SearchInput,
  Password: PasswordInput,
} = AntdInput;

export const InputNumber = (props: InputNumberProps) => {
  return <AntdInputNumber {...props} />;
};

export const Switch = (props: SwitchProps) => {
  return <AntdSwitch {...props} />;
};

export const Select = (props: SelectProps) => {
  const { children } = props;
  return <AntdSelect {...props}>{children}</AntdSelect>;
};
export const { Option, OptGroup: OptionGroup } = AntdSelect;

export const Upload = (props: ExtendedUploadProps) => {
  const { children } = props;
  return <AntdUpload {...props}>{children}</AntdUpload>;
};
export const { Dragger } = AntdUpload;

export const UploadSpreadSheet = ({
  isUploading,
  uploadFile,
  allowCsvSheet,
  allowExcelSheet,
  children,
}: UploadSpreadSheetProps) => {
  const [fileList, setFileList] = useState<Array<any>>([]);

  let acceptSpreadSheet = '';
  const fileTypes = [];
  if (allowCsvSheet) {
    fileTypes.push(csvSheetType);
    acceptSpreadSheet += csvSheetType;
  }
  if (allowExcelSheet) {
    fileTypes.push(xlsSheetType);
    fileTypes.push(xlsxSheetType);
    acceptSpreadSheet += `,${xlsSheetType},${xlsxSheetType}`;
  }

  const beforeUpload = (_file: any) => {
    const { type } = _file;
    if (fileTypes.includes(type)) {
      setFileList([_file]);
      return true;
    } else showErrorToast('Invalid file format. Try uploading a valid file.');

    return false;
  };

  const onRemove = () => {
    setFileList([]);
  };

  const onUpload = () => {
    if (!fileList.length) {
      return showErrorToast('Please select file to proceed');
    }

    uploadFile(fileList[0]);
  };

  return (
    <Row>
      <Column>
        <Dragger
          accept={acceptSpreadSheet}
          multiple={false}
          fileList={fileList}
          beforeUpload={beforeUpload}
          onRemove={onRemove}
        >
          <p>{CloudUpload}</p>
          <h3>Click or drag file to this area to upload</h3>
        </Dragger>
      </Column>
      <Column>{children}</Column>
      <Column>
        <Button
          type="primary"
          onClick={onUpload}
          loading={isUploading}
          block={true}
        >
          Upload
        </Button>
      </Column>
    </Row>
  );
};

export const useVerifyStringInput = (verificationString: string) => {
  const [inputValue, setInputValue] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  const onChange = (event: any) => {
    const { target } = event;
    const { value } = target;
    if (
      value == verificationString ||
      value == verificationString.toLowerCase()
    ) {
      setIsVerified(true);
    } else setIsVerified(false);
    setInputValue(value);
  };

  return {
    isVerified: isVerified,
    render: (
      <Input
        placeholder={`Type ${verificationString} to confirm`}
        value={inputValue}
        onChange={onChange}
      />
    ),
  };
};

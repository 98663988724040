import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  ClusterOutlined,
  ControlOutlined,
  CrownOutlined,
  DashboardOutlined,
  FileExclamationOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  FilterOutlined,
  InboxOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SoundOutlined,
  ToolOutlined,
  CloseOutlined,
  LeftCircleFilled,
  MenuOutlined,
  RightCircleFilled,
  ReloadOutlined,
  EditOutlined,
  CheckOutlined,
} from '@ant-design/icons';

export const CloudDownload = <CloudDownloadOutlined />;
export const CloudUpload = <CloudUploadOutlined />;
export const Cluster = <ClusterOutlined />;
export const FileText = <FileTextOutlined />;
export const Filter = <FilterOutlined />;

export const Dashboard = <DashboardOutlined />; // use for Dashboard
export const ShoppingCart = <ShoppingCartOutlined />; // use for orders
export const Shop = <ShopOutlined />; // use for inventory
export const Inbox = <InboxOutlined />; // use for data dump
export const FileSync = <FileSyncOutlined />; // use for Monitoring
export const Reconciliation = <ReconciliationOutlined />; // use for reconciliation
export const FileExclamation = <FileExclamationOutlined />; // use for blacklist
export const Control = <ControlOutlined />; // use for utilities
export const Crown = <CrownOutlined />; // use for shopcast
export const Sound = <SoundOutlined />; // use for Marketing
export const Setting = <SettingOutlined />; // use for settings
export const Tool = <ToolOutlined />; // use for Admin Panel
export const Close = <CloseOutlined />;
export const LeftCircle = <LeftCircleFilled />;
export const RightCircle = <RightCircleFilled />;
export const MenuIcon = <MenuOutlined />;
export const ReloadIcon = <ReloadOutlined />;
export const Edit = <EditOutlined />;
export const Check = <CheckOutlined />;

import auth_failed from 'src/assets/authentication_failed.svg';
import bad_request from 'src/assets/bad_request.svg';
import check from 'src/assets/check.svg';
import clear from 'src/assets/clear.svg';
import clear_filter from 'src/assets/clear_filter.svg';
import cross from 'src/assets/cross.svg';
import download from 'src/assets/download.svg';
import dustbin from 'src/assets/dustbin.svg';
import error from 'src/assets/error.svg';
import filter from 'src/assets/filter.svg';
import gear from 'src/assets/gear.svg';
import info from 'src/assets/information.svg';
import pencil from 'src/assets/pencil.svg';
import person from 'src/assets/person.svg';
import plus from 'src/assets/plus.svg';
import report from 'src/assets/report.svg';
import send from 'src/assets/send.svg';
import success from 'src/assets/success.svg';
import upload from 'src/assets/upload.svg';
import warning from 'src/assets/warning.svg';

export const AuthFailedIcon = (
  <img src={auth_failed} alt="authentication failed" />
);
export const BadReqIcon = <img src={bad_request} alt="bad request" />;
export const CheckIcon = <img src={check} alt="check" />;
export const ClearIcon = <img src={clear} alt="clear" />;
export const ClearFilterIcon = <img src={clear_filter} alt="clear filter" />;
export const CrossIcon = <img src={cross} alt="cross" />;
export const DownloadIcon = <img src={download} alt="download" />;
export const DustbinIcon = <img src={dustbin} alt="dustbin" />;
export const ErrorIcon = <img src={error} alt="error" />;
export const FilterIcon = <img src={filter} alt="filter" />;
export const GearIcon = <img src={gear} alt="gear" />;
export const InfoIcon = <img src={info} alt="info" />;
export const PencilIcon = <img src={pencil} alt="pencil" />;
export const PersonIcon = <img src={person} alt="person" />;
export const PlusIcon = <img src={plus} alt="plus" />;
export const ReportIcon = <img src={report} alt="report" />;
export const SendIcon = <img src={send} alt="send" />;
export const SuccessIcon = <img src={success} alt="success" />;
export const UploadIcon = <img src={upload} alt="upload" />;
export const WarningIcon = <img src={warning} alt="warning" />;

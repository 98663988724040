import { message } from 'antd';

export const showSuccessToast = (msg: string) => {
  message.success(msg).then(null);
};

export const showErrorToast = (msg: string) => {
  message.error(msg).then(null);
};

export const showInfoToast = (msg: string) => {
  message.info(msg).then(null);
};

export const showWarningToast = (msg: string) => {
  message.warning(msg).then(null);
};

export const showLoadingToast = (msg: string) => {
  message.loading(msg).then(null);
};

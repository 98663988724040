import {
  Alert as AntdAlert,
  AlertProps,
  Drawer as AntdDrawer,
  DrawerProps,
  Modal as AntdModal,
  ModalProps,
  Popconfirm as AntdPopconfirm,
  PopconfirmProps,
  Progress as AntdProgress,
  ProgressProps,
  Result as AntdResult,
  ResultProps,
  Skeleton as AntdSkeleton,
  SkeletonProps,
  Spin as AntdSpin,
  SpinProps,
} from 'antd';
import React from 'react';

import { Button } from './Generals';
import { ReloadIcon } from './Icons';
import { Column, Row, Space } from './Layouts';

type ExtendedDrawerProps = DrawerProps & {
  children?: React.ReactNode;
};

type ExtendedModalProps = ModalProps & {
  children?: React.ReactNode;
  footer?: React.ReactNode;
};

type FiltersDrawerProps = {
  visible: boolean;
  onApply: () => void;
  onReset: () => void;
  onCancel: () => void;
  children: React.ReactNode;
};

type ModalFooterProps = {
  formName?: React.ReactNode;
  okTitle?: string;
  cancelTitle?: string;
  onOk?: () => void;
  onCancel?: () => void;
  disableOkBtn?: boolean;
  disableCancelBtn?: boolean;
  isLoading?: boolean;
};

export const Alert = (props: AlertProps) => {
  return <AntdAlert {...props} />;
};

export const Drawer = (props: ExtendedDrawerProps) => {
  const { children } = props;

  return (
    <AntdDrawer {...props}>
      <article>{children}</article>
    </AntdDrawer>
  );
};

export const FiltersDrawer = ({
  visible,
  onApply,
  onReset,
  onCancel,
  children,
}: FiltersDrawerProps) => {
  const footer = (
    <Row>
      <Column className="text-left" size={12}>
        <Button type="primary" onClick={onApply}>
          Apply
        </Button>
      </Column>
      <Column className="text-right" size={12}>
        <Button onClick={onCancel}>Cancel</Button>
      </Column>
    </Row>
  );

  return (
    <AntdDrawer title="Filters" visible={visible} footer={footer}>
      <article className="drawer-body">
        <section className="content">{children}</section>
        <section className="footer">
          <Button type="link" onClick={onReset} icon={ReloadIcon}>
            Reset
          </Button>
        </section>
      </article>
    </AntdDrawer>
  );
};

export const Modal = (props: ExtendedModalProps) => {
  const {
    footer = null,
    centered = true,
    destroyOnClose = true,
    children,
  } = props;
  return (
    <AntdModal
      footer={footer}
      centered={centered}
      destroyOnClose={destroyOnClose}
      {...props}
    >
      {children}
    </AntdModal>
  );
};

export const ModalFooter = ({
  formName = null,
  okTitle = 'Ok',
  cancelTitle = 'Cancel',
  onOk,
  onCancel,
  disableOkBtn = false,
  disableCancelBtn = false,
  isLoading = false,
}: ModalFooterProps) => {
  const OkProps: any = {
    htmlType: formName ? 'submit' : 'button',
    type: 'primary',
    onClick: onOk,
    disabled: disableOkBtn,
    loading: isLoading,
  };
  if (formName) OkProps.form = formName;

  return (
    <div className="text-center">
      <Space>
        <Button {...OkProps}>{okTitle}</Button>
        <Button onClick={onCancel} disabled={disableCancelBtn}>
          {cancelTitle}
        </Button>
      </Space>
    </div>
  );
};

export const Popconfirm = (props: PopconfirmProps) => {
  const { children } = props;
  return <AntdPopconfirm {...props}>{children}</AntdPopconfirm>;
};

export const Progress = (props: ProgressProps) => {
  return <AntdProgress {...props} />;
};

export const Result = (props: ResultProps) => {
  return <AntdResult {...props} />;
};

export const Skeleton = (props: SkeletonProps) => {
  const {
    active = true,
    avatar = false,
    loading = true,
    paragraph = true,
    title = true,
    round = true,
  } = props;
  return (
    <AntdSkeleton
      active={active}
      avatar={avatar}
      loading={loading}
      paragraph={paragraph}
      title={title}
      round={round}
      {...props}
    />
  );
};

export const Spin = (props: SpinProps) => {
  const { size = 'default', spinning = true } = props;
  return <AntdSpin size={size} spinning={spinning} {...props} />;
};

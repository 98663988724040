import {
  Breadcrumb as AntdBreadcrumb,
  BreadcrumbProps,
  Dropdown as AntdDropdown,
  DropDownProps,
  Menu as AntdMenu,
  MenuProps,
  PageHeader as AntdPageHeader,
  PageHeaderProps,
  Pagination as AntdPagination,
  PaginationProps,
} from 'antd';
import React from 'react';

import { pageSizeOptions } from 'src/constants';

type ExtendedBreadcrumbProps = BreadcrumbProps & {
  children?: React.ReactNode;
};

type ExtendedDropdownProps = DropDownProps & {
  children?: React.ReactNode;
};

export const Breadcrumb = (props: ExtendedBreadcrumbProps) => {
  const { children } = props;
  return <AntdBreadcrumb>{children}</AntdBreadcrumb>;
};
export const { Item: BreadcrumbItem } = AntdBreadcrumb;

export const Dropdown = (props: ExtendedDropdownProps) => {
  const { children } = props;
  return <AntdDropdown {...props}>{children}</AntdDropdown>;
};
export const { Button: DropdownButton } = AntdDropdown;

export const Menu = (props: MenuProps) => {
  const { mode = 'inline', theme = 'light', children } = props;
  return (
    <AntdMenu mode={mode} {...props} theme={theme}>
      {children}
    </AntdMenu>
  );
};
export const { Item: MenuItem, SubMenu } = AntdMenu;

export const PageHeader = (props: PageHeaderProps) => {
  return <AntdPageHeader {...props} />;
};

export const Pagination = (props: PaginationProps) => {
  return <AntdPagination pageSizeOptions={pageSizeOptions} {...props} />;
};

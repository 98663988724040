import { useMutation } from 'react-query';

import axios from 'src/lib/axios';
import { notifyError, notifySuccess } from 'src/utils/notification';

const notifyTitle = 'Reset Password';
const successMsg = (email: string) => `Password reset link sent to ${email}`;
const resetErrorMsg = 'Cannot reset password at the moment';
const inValidEmailErrorMsg = 'This email is not registered';

const validateEmail = (email: string) => {
  const formData = new FormData();
  formData.append('email', email);

  return axios.post('/api/oauth/users/validateEmail', formData);
};

const sendEmail = (email: string) => {
  const formData = new FormData();
  formData.append('email', email);

  return axios.post('/api/oauth/users/sendEmail', formData);
};

export const useValidateEmail = (email: string, onSuccess: () => void) =>
  useMutation(['validateEmail'], () => validateEmail(email), {
    onSuccess: onSuccess,
    onError: () => notifyError(notifyTitle, inValidEmailErrorMsg),
  });

export const useSendEmail = (email: string, onSuccess: () => void) =>
  useMutation(['sendEmail'], () => sendEmail(email), {
    onSuccess: () => {
      onSuccess();
      notifySuccess(notifyTitle, successMsg(email));
    },
    onError: () => notifyError(notifyTitle, resetErrorMsg),
  });
